import React, { useState, useEffect, useRef } from "react";
import videojs from "video.js";
import "videojs-youtube";
import "video.js/dist/video-js.css";
import "./Course.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TesztPopup from "./TesztPopup";
import Timer from "./TimerTest";
import BigTest from "./BigTest";
import axios from "axios";

const Course = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseVideos, setCourseVideos] = useState([]);
  const customId = "custom-id-yes";
  const [courses, setCourses] = useState([]);
  const [tests, setTests] = useState([]);
  const [selectedCourseforTest, setSelectedCourseforTest] = useState([]);
  const [selectedTest, setSelectedTest] = useState([]);
  const [tesztWindowIsOpen, setTesztWindowsIsOpen] = useState(false);
  const [bigTesztWindowIsOpen, setBigTesztWindowIsOpen] = useState(false);
  const [lastTestResult, setLastTestResult] = useState(null);
  const [isslimfigther,setIsslimfigther]=useState(parseInt(localStorage.getItem("isslimfigther")));
console.log("szutykospunvi2",isslimfigther,typeof(isslimfigther))
  const [remainedCourses, setRemainedCourses] = useState([]);
  const [
    filteredCourseByMonthAndTestSuccess,
    setFilteredCourseByMonthAndTestSuccess,
  ] = useState([]);
  const [successMessage,setSuccessMessage]=useState(0);
  const [readyForBigTest, setReadyForBigTest] = useState(0);
  const [testresult, setTestresult] = useState();
  const [testcanBeStart, settestcanBeStart] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0); // Kezdőérték
  const actualmonth = localStorage.getItem("actualmonth");

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    fetch("https://academy.slimstore.hu/api/get_coursecategory.php")
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          toast.error("Hiba történt a kurzusok lekérésekor!", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        } else {
          setCourses(data.courses);
        }
      })
      .catch(() => {
        toast.error("Szerverhiba! Nem sikerült a kurzusok lekérése.", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
      });
    const id = localStorage.getItem("id");
    console.log("futyike", actualmonth, id);
    fetch("https://academy.slimstore.hu/api/monthly_refresh_test_results.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded", // A POST adatokat URL-enkódolva küldjük
      },
      body: new URLSearchParams({
        year_month: actualmonth, // Helyes kulcsnév
        id: id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Hiba történt a kérés során.");
        }
        return response.json(); // JSON-válasz feldolgozása
      })
      .then((data) => {
        if (data.success) {
          console.log("Lekérdezés eredménye:", data.data);

          const nextAvailableCourses = data.data
            .filter((item) => item.passed === 1) // Csak azok az elemek maradnak, ahol pass === 1
            .map((item) => item.next_available_course); // Csak a next_available_course értékek kerülnek a tömbbe
          console.log("hoki", nextAvailableCourses);

          setFilteredCourseByMonthAndTestSuccess(nextAvailableCourses);
          // Itt kezelheted a sikeres válaszban kapott adatokat
        } else {
          console.error("Hiba az SQL lekérdezésben:", data.error);
        }
      })
      .catch((error) => {
        console.error("Hálózati vagy egyéb hiba:", error.message);
      });
  }, [testresult]);

  const groupedCourses = {
/*     "1.rész": courses.filter((course) => course.category === "1.rész"),
    "2.rész": courses.filter((course) => course.category === "2.rész"),
    "3.rész": courses.filter((course) => course.category === "3.rész"), */

    "Értékesítési alapok": courses.filter((course) => course.category === "Értékesítési alapok"),
    "Termékismeret": courses.filter((course) => course.category === "Termékismeret"),
    "Eladási folyamatok": courses.filter((course) => course.category === "Eladási folyamatok"),
    "Adminisztráció": courses.filter((course) => course.category === "Adminisztráció"),
    "Motiváció": courses.filter((course) => course.category === "Motiváció"),

  };
  console.log("groupedCourses", groupedCourses);
  const handleSelectCourse = (course) => {
    setSelectedCourse(course);
    setIsSidebarOpen(!isSidebarOpen);
    fetchCourseVideos(course.id);
  };

  const fetchCourseVideos = (maincourseid) => {
    fetch(
      `https://academy.slimstore.hu/api/get_course_videos.php?maincourseid=${maincourseid}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          toast.error("Hiba történt a videók lekérésekor!", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        } else {
          setCourseVideos(data.videos);
        }
      })
      .catch(() => {
        toast.error("Szerverhiba! Nem sikerült a videók lekérése.", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    const userId = localStorage.getItem("id");
    if (selectedCourse && userId) {
      fetchLastTestResult(selectedCourse.id, userId).then((result) => {
        setLastTestResult(result);

        if (result) {
          const currentTime = new Date();
          const completionTime = new Date(result.completion_date);
          const timeDifference = Math.floor(
            (currentTime - completionTime) / 1000
          );

          if (result.passed === 0 && timeDifference < 10) {
            // Visszaszámlálás
            setTimeLeft(10 - timeDifference);
            settestcanBeStart(false);
          } else if (result.passed === 0) {
            setTimeLeft(0);
            settestcanBeStart(true);
          } else {
            setTimeLeft(0);
            settestcanBeStart(true);
          }
        } else {
          settestcanBeStart(true);
          setTimeLeft(0);
        }
      });
    }
    console.log("fütykos", testcanBeStart, timeLeft);
  }, [selectedCourse, tesztWindowIsOpen]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      console.log("fütykos3", testcanBeStart, timeLeft);
      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      settestcanBeStart(false);
    }
  }, []);

  const VideoPlayer = ({ videoUrl }) => {
    const videoRef = useRef(null);
    const player = useRef(null);

    useEffect(() => {
      const timer = setTimeout(() => {
        if (videoRef.current) {
          player.current = videojs(videoRef.current, {
            controls: true,
            responsive: true,
            fluid: true,
            techOrder: ["html5"], // Az MP4 fájlok HTML5 technológiával játszhatók le
            sources: [
              {
                src: videoUrl, // Az MP4 videó URL-je
                type: "video/mp4", // Az MP4 fájl típusa
              },
            ],
          });
        }
      }, 100);

      return () => {
        clearTimeout(timer);
        if (player.current) {
          player.current.dispose();
          player.current = null;
        }
      };
    }, [videoUrl]);

    return (
      <div className="video-container">
        <video ref={videoRef} className="video-js" />
      </div>
    );
  };
  const closeTesztWindow = (testresult) => {
    console.log("testresult", testresult);
    setTestresult(testresult);

    setTesztWindowsIsOpen(false);
  };

  const closeBigTesztWindow = (testresult) => {
    console.log("testresult", testresult);
    setTestresult(testresult);

    setBigTesztWindowIsOpen(false);
  };

  const openTesztWindow = () => {
    setTesztWindowsIsOpen(true);
  };

  const openBigTesztWindow = () => {
    setBigTesztWindowIsOpen(true);
  };

  const fetchLastTestResult = async (courseId, userId) => {
    try {
      const response = await fetch(
        `https://academy.slimstore.hu/api/get_last_testresult.php?course_id=${courseId}&user_id=${userId}`
      );
      const data = await response.json();
      if (data.success) {
        return data.testResult;
      } else {
        return null;
      }
    } catch (error) {
      toast.error("Hiba történt a teszteredmény lekérésekor.", {
        position: "bottom-center",
        toastId: customId,
        theme: "colored",
      });
      console.error("Error fetching last test result:", error);
      return null;
    }
  };

  useEffect(() => {
    fetch("https://academy.slimstore.hu/api/get_tests.php")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setTests(data.tests);
        } else {
          toast.error("Hiba történt a tesztek lekérésekor!", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        }
      })
      .catch(() => {
        toast.error("Szerverhiba! Nem sikerült a tesztek lekérése.", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
      });
  }, []);

  useEffect(() => {
    setSelectedCourseforTest(selectedCourse);
    console.log("cumika", selectedCourse);
    setSelectedTest(tests);
  }, [tests, selectedCourse]);

  //ha minden kurzus teljesített akkor elérhetővé tesszük a nagyteszt gombot
  useEffect(() => {
    const userid = localStorage.getItem("id");
    fetch(
      `https://academy.slimstore.hu/api/get_undone_course.php?user_id=${userid}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // Itt dolgozd fel a kapott adatokat
        setReadyForBigTest(data.courses.length);
        setRemainedCourses(data.courses);
        console.log("elmaradt kurzusok", data.courses);
        //itt mentjük fel ha minden teszt sikeres akkor megkapja a felhasználó a superuseri jogot.
     /*    if (data.courses.length === 0) {
          updateSlimfighter(userid)
        } */
      })
      .catch((error) => {
        console.error("Hálózati vagy szerverhiba:", error);
        toast.error("Szerverhiba! Nem sikerült a kurzusok lekérése.", {
          position: "bottom-center",
          theme: "colored",
        });
      });
  }, [filteredCourseByMonthAndTestSuccess]);
 

  
  const updateSlimfighter = async (id) => {
    const userid = parseInt(id);
    try {
      const response = await axios.post(
        "https://academy.slimstore.hu/api/update_slimfigther.php",
        { id: userid },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.data.status === "success") {
        toast.success("Gratulálunk! Szuperharcossá váltál! 🎉", {
          position: "top-right",
          autoClose: false, // Nem tűnik el automatikusan
          closeOnClick: true, // Manuálisan zárható
          draggable: true, // Húzással mozgatható
          theme: "colored", // Színes stílus
          toastId: "superharcos-success", // Egyedi azonosító
        });
        console.log("Frissítés sikeres:", response.data.message);
      } else {
        toast.error("Hiba a frissítés során!", {
          theme: "colored",
        });
        console.error("Hiba a frissítés során:", response.data.message);
      }
    } catch (error) {
      toast.error("Hiba történt a mentés során!", {
        theme: "colored",
      });
      console.error("Hiba történt:", error);
    }
  };

  useEffect(()=>{
    if(isslimfigther!==1){
      if(readyForBigTest===0 ){
        const id = localStorage.getItem("id");
        updateSlimfighter(id);

      setIsslimfigther(localStorage.setItem("isslimfigther", 1));
        setSuccessMessage(1)
      }

    }


  },[successMessage!==1])

  return (
    <div className="course-container">
      <ToastContainer />
      <button className="course-toggle-arrow" onClick={toggleSidebar}>
        {isSidebarOpen ? "❮" : "❯"}
      </button>
      <div className={`coursesidebar ${isSidebarOpen ? "open" : "hidden"}`}>
        <h2 className="slidebarheader">Üdv Slimstore harcos</h2>
        <ul>
          {Object.keys(groupedCourses).map((category, index) => (
            <li key={index}>
              <h3>{category}</h3>
              <ul>
                {groupedCourses[category].map((course, courseIndex) => (
                  <li key={courseIndex}>
                    <button
                      onClick={() => handleSelectCourse(course)}
                      className="course-item-btn"
                      disabled={
                        course.course_order !== 1 &&
                        !filteredCourseByMonthAndTestSuccess.includes(
                          course.course_order
                        )
                      }
                    >
                      <strong>{course.coursename}</strong>
                    </button>
                  </li>
                ))}
              </ul>
            </li>
          ))}
          <ul>
            <li>
              <button
                onClick={openBigTesztWindow}
                className="big-test-btn"
                disabled={ (readyForBigTest !== 0 || isslimfigther===0)} // Ha `readyForBigTest` nem 0, a gomb le van tiltva
              >
                <strong>Nagyteszt</strong>
              </button>
           
            </li>
          </ul>
        </ul>
      </div>
      <div className="actual-course">
        <header>
          <h1>Üdvözöljük a SlimStore Egyetem kurzusán!</h1>
        </header>
        <main>
          {selectedCourse ? (
            <div className="selected-course">
              <h2>{selectedCourse.coursename}</h2>
              <p>{selectedCourse.description}</p>
              <a
                href={`https://academy.slimstore.hu/uploads/${selectedCourse.file_url}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ cursor: "pointer", color: "blue" }}
              >
                Tananyag megnyitása új ablakban
              </a>

              <h3 style={{ textAlign: "center" }}>Kurzushoz tartozó videók</h3>
              {courseVideos.length > 0 ? (
                courseVideos.map((video, index) => (
                  <div key={index} className="video-container">
                    <h4>{video.title}</h4>
                    <VideoPlayer videoUrl={video.videourl} />
                  </div>
                ))
              ) : (
                <p>Nincsenek elérhető videók ehhez a kurzushoz.</p>
              )}

              {timeLeft > 0 && (
                <Timer
                  initialTime={timeLeft}
                  onComplete={() => {
                    settestcanBeStart(true); // Ha lejárt az idő, engedélyezzük a tesztindítást
                  }}
                />
              )}
              <button
                onClick={openTesztWindow}
                style={{
                  background: "yellow",
                  borderRadius: "10px",
                  align: "center",
                }}
                disabled={!testcanBeStart}
              >
                Teszt indítása
              </button>

              {readyForBigTest !== 0 && isslimfigther !== 1 ? (
                remainedCourses.map((item, index) => (
                  <div key={index}>
                    {/* Ellenőrizd, hogy ez az utolsó elem-e */}
                    {index === remainedCourses.length - 1 && (
                      <div>
                        <p>Megmaradt kurzusok:</p>
                        {/* Másik map a maradék kurzusok megjelenítésére */}
                        {remainedCourses.map((course, subIndex) => (
                          <div key={subIndex}>{course.coursename}</div>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div>Nincs további teendőd, irány a nagytesztre</div>
              )}
            </div>
          ) : (
            <p>
              Üdv, Slimstore harcos. Egyetemünkön megtanulhatsz mindent, ami
              szükséges ahhoz, hogy te légy a legjobb. Sok sikert!
            </p>
          )}
        </main>
        <footer>
          <p>&copy; 2024 Minden jog fenntartva.</p>
        </footer>
      </div>

      {tesztWindowIsOpen && (
        <TesztPopup
          show={tesztWindowIsOpen}
          onClose={closeTesztWindow}
          tests={selectedTest}
          actualCourse={selectedCourseforTest}
        />
      )}

      {bigTesztWindowIsOpen && (
        <BigTest
          show={bigTesztWindowIsOpen}
          onClose={closeBigTesztWindow}
          tests={tests}
          courses={courses}
        />
      )}
    </div>
  );
};

export default Course;
